import React, { useState, useEffect } from "react";
import {
  Tabs,
  Button,
  Carousel,
  Collapse,
  Typography,
  Input,
  message,
  Spin,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { BiSolidFoodMenu } from "react-icons/bi";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdOutlineSecurity } from "react-icons/md";
import { VscChecklist } from "react-icons/vsc";
import "./UserOrderPage.css";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Text } = Typography;

const UserOrderPage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [phoneNumber, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [activePanels, setActivePanels] = useState([]);
  const { company } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/api/menu/${company}`)
      .then((response) => {
        setMenuItems(response.data.items);
        const groupedItems = response.data.items.reduce((acc, item) => {
          const { category } = item;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(item);
          return acc;
        }, {});
        // Automatically expand all panels by setting all keys as active
        const initialActiveKeys = Object.keys(groupedItems);
        setActivePanels(initialActiveKeys);
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
        message.error("Failed to load menu items.");
      });
  }, [company]);

  const handleQuantityChange = (item, delta) => {
    const updatedItems = [...selectedItems];
    const existingItem = updatedItems.find((i) => i._id === item._id);
    if (existingItem) {
      existingItem.quantity = Math.max(0, existingItem.quantity + delta);
      if (delta < 0 && existingItem.quantity !== 0)
        message.error(`${item.itemName} removed from cart`);
      if (delta < 0 && existingItem.quantity === 0) {
        const index = updatedItems.indexOf(existingItem);
        updatedItems.splice(index, 1);
      }
    } else if (delta > 0) {
      updatedItems.push({ ...item, quantity: delta });
      message.success(`${item.itemName} added to cart`);
    }

    setSelectedItems(updatedItems);
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value;
    setPhone(inputPhone);

    const phoneRegex = /^[6-9]\d{9}$/;
    setIsPhoneValid(phoneRegex.test(inputPhone));
  };

  const handlePayment = () => {
    if (!isPhoneValid) {
      message.error("Please enter a valid phone number.");
      return;
    }
    // Create the order in the backend and generate a unique order ID
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/payment/create-order`,
        {
          items: selectedItems,
          company,
          amount: calculateTotal(),
          phoneNumber,
        }
      )
      .then((response) => {
        //If order creation is successful, proceed to payment
        if (response.data.success) {
          // Redirect to PhonePe payment page with the payment URL
          window.location.href = response.data.paymentUrl;
        } else {
          message.error("Failed to create order.");
        }
      })
      .catch((error) => {
        console.error("Order creation error:", error);
        message.error("An error occurred while creating the order.");
      });
  };

  const groupedItems = menuItems.reduce((acc, item) => {
    const { category } = item;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const calculateTotal = () => {
    return selectedItems.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
  };

  const renderOrderSummary = () => {
    return selectedItems?.map((item) => (
      <div key={item._id} style={{ marginBottom: "8px" }}>
        <Text>
          {item.itemName} × {item.quantity} × {item.price} ={" "}
          {item.quantity * item.price}
        </Text>
      </div>
    ));
  };

  const renderVegIndicator = (type) => (
    <div
      style={{
        display: "inline-block",
        width: "15px",
        height: "15px",
        borderRadius: "3px",
        border: `2px solid ${type === "veg" ? "green" : "red"}`,
        position: "relative",
        padding: "7px",
        marginRight: "5px",
      }}
    >
      <div
        style={{
          backgroundColor: type === "veg" ? "green" : "red",
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab="One Time Order"
          key="1"
          className="carousel-image-container"
          style={{ height: "100%", width: "100%" }}
        >
          <div>
            {menuItems.length > 0 ? (
              <Carousel
                arrows
                autoplay
                style={{ height: "100%", width: "100%" }}
              >
                {menuItems
                  .filter((item) => item.imageUrl?.length > 0)
                  .map((item, index) => (
                    <div className="carousel-image-container" key={index}>
                      <img
                        className="carousel-image"
                        src={`${process.env.REACT_APP_BACKEND_API_URL}${item.imageUrl[0]}`}
                        alt={`item ${item.itemName}`}
                      />
                    </div>
                  ))}
              </Carousel>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" />
                <p>Loading items...</p>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <BiSolidFoodMenu style={{ marginRight: "8px" }} />
            <Text style={{ fontWeight: "bold" }}>MENU</Text>
          </div>
          <Collapse
            className="panel-container"
            activeKey={activePanels}
            onChange={setActivePanels} // Make the panels collapsible by updating the state on panel toggle
          >
            {Object.keys(groupedItems).map((category) => (
              <Panel header={category} key={category}>
                <div className="menu-container">
                  {groupedItems[category].map((item) => (
                    <div
                      key={item._id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {renderVegIndicator(item.type)}
                          <strong>{item.itemName} </strong>
                        </div>
                        <strong>Rs.{item.price}</strong>
                        <p>{item.description}</p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          icon={<MinusOutlined style={{ width: "10px" }} />}
                          onClick={() => handleQuantityChange(item, -1)}
                          disabled={
                            selectedItems.find((i) => i._id === item._id)
                              ?.quantity <= 0
                          }
                        />
                        <span
                          style={{
                            display: "inline-block",
                            width: "40px",
                            textAlign: "center",
                          }}
                        >
                          {selectedItems.find((i) => i._id === item._id)
                            ?.quantity || 0}
                        </span>
                        <Button
                          icon={<PlusOutlined style={{ width: "10px" }} />}
                          onClick={() => handleQuantityChange(item, 1)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Panel>
            ))}
          </Collapse>
          <div className="order-summary">
            <strong>
              <VscChecklist /> Order Summary
            </strong>
            {renderOrderSummary()}
            <Text>Total: Rs.{calculateTotal()}</Text>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "16px",
              marginBottom: "2%",
            }}
            className="phone-container"
          >
            <Input
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Enter your phone number"
              className="phone-input"
            />
            <Button
              type="primary"
              onClick={handlePayment}
              style={{ marginTop: "8px", borderRadius: "7px" }}
              disabled={!isPhoneValid}
            >
              <MdOutlineSecurity /> Proceed to Pay
            </Button>
          </div>
        </TabPane>
        <TabPane tab="Subscription" key="2">
          <div style={{ marginTop: "16px", marginLeft: "25px" }}>
            <h3>We are working on this Page! Thank you for visiting us!</h3>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserOrderPage;
